// Import everything from autoload folder
/* global google */
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line

// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';
import Player from '@vimeo/player'; //eslint-disable-line

/**
 * Import scripts from Custom Divi blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/divi/example-block/index.js';

/**
 * Import scripts from Custom Elementor widgets
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/elementor/example-widget/index.js';

/**
 * Import scripts from Custom ACF Gutenberg blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/gutenberg/acf-example-block/index.js';

/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

function slickFacet() {
  if ($(window).width() < 640) {
    let heightPost = $('.facility-post').height();
    $('.wrap').css('min-height', heightPost);
    document.addEventListener('facetwp-loaded', function () {
      $('.facility-slider').slick({
        centerMode: true,
      });
      $('.facility-slider').fadeIn();
      $('.lds-roller').fadeOut();
    });

    document.addEventListener('facetwp-refresh', function () {
      $('.facility-slider').slick('unslick');
      $('.facility-slider').fadeOut();
      $('.lds-roller').fadeIn();
    });
  }
}

// function fixPosition() {
//   let heightSection = $('.video-section .content-wrap').height();
//   let nextSection = $('.events-section');
//   nextSection.css('padding-top', 'calc(' + heightSection + 'px - 10%)');
// }

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  /**
   * Make elements equal height
   */
  slickFacet();
  // fixPosition();
  $('a.iframe-link').fancybox({
    type: 'iframe',
  });

  $('#mec_sf_category_1311 option[value]:first').text('Sport');

  $('.matchHeight').matchHeight();

  $('.page-elem .elem-title').matchHeight();

  $('.events-cards .card .card-title').matchHeight();
  $('.events-cards .card .card-excerpt').matchHeight();

  $('.event-post h2').matchHeight();
  $('.event-post .excerpt').matchHeight();

  $('.slider-cards .card .card-text h2').matchHeight();

  $('.four_box-item').matchHeight();

  $('.term-wrap h3').matchHeight();
  $('.term-wrap h5').matchHeight();

  $('.facility-post .title-wrap').matchHeight();
  $('.facility-post .excerpt').matchHeight();

  $('.persone-info .read-more').on('click', function () {
    $(this).siblings('.wrap-info').css('height', '100%');
    $(this).css('display', 'none');
  });

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  $('.slider-section').slick({
    infinite: true,
    arrows: false,
    speed: 300,
    rows: 0,
  });

  $('.slider-cards').slick({
    infinite: true,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 300,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640,
        settings: 'unslick',
      },
    ],
  });

  if ($(window).width() < 640) {
    $('.enother-page-section .cards-wrapper').slick({
      infinite: false,
      dots: false,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      speed: 300,
    });

    $('.enother-page-section .cards-wrapper .arrow-next').on(
      'click',
      function () {
        $(this).parents('.cards-wrapper').slick('slickNext');
      }
    );
  }

  if ($(window).width() < 768) {
    $('.video .video-wrapper').slick({
      infinite: false,
      dots: false,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      speed: 300,
    });

    $('.video .video-wrapper .video-item .arrow-next').on('click', function () {
      $(this).parents('.video-wrapper').slick('slickNext');
    });
  }

  $('.home-hero .terms-wrap').slick({
    infinite: false,
    dots: false,
    arrows: false,
    slidesToShow: 10,
    slidesToScroll: 1,
    speed: 300,
    responsive: [
      {
        breakpoint: 1700,
        settings: {
          slidesToShow: 9,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1512,
        settings: {
          slidesToShow: 8,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1324,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1136,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 948,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 760,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 572,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 439,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: true,
        },
      },
    ],
  });

  $('.close-menu-button').on('click', function () {
    $(this).parents('.custom-submenu').removeClass('js-dropdown-active');
    $(this).parents('li').removeClass('is-active').attr('data-is-click', false);
  });

  $('.menu-icon').on('click', function () {
    if ($(this).hasClass('is-active')) {
      $('body').addClass('no-scroll');
    } else {
      $('body').removeClass('no-scroll');
    }
  });
  if ($(window).width() < 640) {
    $('.sport-mobile-slider').slick({
      infinite: false,
      dots: false,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      speed: 300,
    });

    $('.sport-mobile-slider .slick-slide .arrow-next').on('click', function () {
      $(this).parents('.sport-mobile-slider').slick('slickNext');
    });
  }

  if ($(window).width() > '884') {
    $(document).on('click', '.tribe-events-calendar-month__day', function () {
      let rowWeek = $(this).parent();
      let block = $(this).find('div.tribe-events-calendar-month__events');
      let article = block.children('article');
      article
        .appendTo(rowWeek)
        .wrapAll("<div class='tribe-events-calendar-month__events'></div>");

      $(document).mouseup(function (e) {
        if (!article.is(e.target) && article.has(e.target).length === 0) {
          article.appendTo(block);
          rowWeek.children('.tribe-events-calendar-month__events').remove();
        }
      });
    });
  }

  var btn = $('#button-top');
  $(window).on('scroll', function () {
    // Add class to scroll-button
    if ($(window).scrollTop() > 50) {
      btn.addClass('show');
    } else {
      btn.removeClass('show');
    }
  });

  btn.on('click', function (e) {
    e.preventDefault();
    $('html, body').animate({ scrollTop: 0 }, '300');
  });

  //Fancybox
  $('.popup-button').click(function () {
    let popupId = $(this).siblings('.popup-cupone').attr('id');
    $.fancybox.open({
      src: '#' + popupId,
      type: 'inline',
    });
  });

  $('.four_box-item .image-wrap').on('mouseenter', function () {
    $(this).find('img').fadeOut();
    let iframe = $(this).find('iframe')[0];
    const player = new Player(iframe); //eslint-disable-line
    player.play();
  });

  $('.four_box-item .image-wrap').on('mouseleave', function () {
    $(this).find('img').fadeIn();
    let iframe = $(this).find('iframe')[0];
    const player = new Player(iframe); //eslint-disable-line
    player.pause();
  });
  $('form#gform_2').on('submit', function () {
    console.log('test');
  });

  $('.next-slider-button').on('click', function () {
    $(this).parents('.slick-slider').slick('slickNext');
  });

  $('.prev-slider-button').on('click', function () {
    $(this).parents('.slick-slider').slick('slickPrev');
  });

  $('.next-slider-button').on('click', function () {
    $(this).parents('.slider-column').find('.slick-slider').slick('slickNext');
  });

  $('.prev-slider-button').on('click', function () {
    $(this).parents('.slider-column').find('.slick-slider').slick('slickPrev');
  });

  // let markersContainer = [];

  /* global ajax_object */
  let { ajax_url, posts, current_page, max_page } = ajax_object;
  $('.loadmore-button').click(function () {
    var button = $(this),
      data = {
        action: 'loadmore',
        query: posts, // that's how we get params from wp_localize_script() function
        page: current_page,
      };

    $.ajax({
      // you can also use $.post here
      url: ajax_url, // AJAX handler
      data: data,
      type: 'POST',
      beforeSend: function () {
        button.text('Loading...');
        button.addClass('remove-arrow');
      },
      success: function (data) {
        if (data) {
          button.removeClass('remove-arrow');
          button.text('Load more').parent().prev().before(data); // insert new posts
          // console.log(button.prev());
          current_page++;

          if (current_page == max_page) {
            button.remove(); // if last page, remove the button
          }
          // you can also fire the "post-load" event here if you use a plugin that requires it
          // $( document.body ).trigger( 'post-load' );
        } else {
          button.remove(); // if no data, remove the button as well
        }
      },
    });
  });

  function render_map($el) {
    // var
    var $markers = $el.find('.marker');
    var styles = [
      {
        featureType: 'poi',
        elementType: 'all',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'poi.park',
        elementType: 'all',
        stylers: [
          {
            visibility: 'on',
          },
        ],
      },
      {
        featureType: 'transit.station',
        elementType: 'all',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
    ];

    // vars
    var args = {
      zoom: 16,
      center: new google.maps.LatLng(0, 0),
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      scrollwheel: false,
      styles: styles, // Uncomment for map styling
    };

    // create map
    var map = new google.maps.Map($el[0], args);

    // add a markers reference
    map.markers = [];

    // add markers
    $markers.each(function () {
      add_marker($(this), map);
    });

    // center map
    center_map(map);

    $('.titles').on('change', function () {
      var selectedOption = $(this).find('option:selected');

      if (!selectedOption.hasClass('all-location')) {
        var lat = selectedOption.attr('data-lat');
        var lng = selectedOption.attr('data-lng');
        var zoom = selectedOption.attr('data-zoom');
        var latlng = new google.maps.LatLng(lat, lng);
        map.setZoom(+zoom);
        map.setCenter(latlng);
      } else {
        center_map(map);
      }
    });

    $('.trip-location').on('change', function () {
      let selectedOption = $(this).find('option:selected');
      let a = selectedOption.attr('data-marker');

      for (let i = 0; i < map.markers.length; i++) {
        if (
          Number(a) === map.markers[i].id ||
          selectedOption.hasClass('all-location')
        ) {
          map.markers[i].setMap(map);
        } else {
          map.markers[i].setMap(null);
        }
      }
      // map.setZoom(+$marker.attr('data-zoom'));
      center_map(map);
      // map.setCenter(latlng);
      // map.panTo(latlng);
      // map.setZoom(+$(this).attr('data-zoom'));
      // animateMapZoomTo(map, +$marker.attr('data-zoom'));
    });
  }

  var infowindow;

  function add_marker($marker, map) {
    var latlng = new google.maps.LatLng(
      $marker.attr('data-lat'),
      $marker.attr('data-lng')
    );
    var zoom = $marker.attr('data-zoom');

    var marker;
    if ($(window).width() < 640) {
      // create marker for mobile
      marker = new google.maps.Marker({
        position: latlng,
        map: map,
        zoom: zoom,
        icon: {
          labelOrigin: new google.maps.Point(40, 40),
          url: $marker.data('marker-icon'), //uncomment if you use custom marker
          scaledSize: new google.maps.Size(80, 105),
        },
        label: {
          text: $marker.data('title'),
          fontSize: '12px',
        },
        id: $marker.data('marker'),
      });
    } else {
      // create marker for desktop
      marker = new google.maps.Marker({
        position: latlng,
        map: map,
        zoom: zoom,
        icon: {
          labelOrigin: new google.maps.Point(55, 50),
          url: $marker.data('marker-icon'), //uncomment if you use custom marker
        },
        label: {
          text: $marker.data('title'),
        },
        id: $marker.data('marker'),
      });
    }

    // add to array
    map.markers.push(marker);

    // if marker contains HTML, add it to an infoWindow
    if ($.trim($marker.html())) {
      // create info window
      infowindow = new google.maps.InfoWindow();

      // show info window when marker is clicked
      google.maps.event.addListener(marker, 'click', function () {
        // Close previously opened infowindow, fill with new content and open it
        infowindow.close();

        infowindow.setContent($marker.html());
        infowindow.open(map, marker);
        map.setZoom(+marker.zoom);
        map.setCenter(latlng);
      });
    }
  }

  // Select to interactive map
  $('#location').on('change', function () {
    var selectedOption = $(this).find('option:selected');
    $('.titles option').each((i, option) => {
      const optionLocation = $(option).attr('data-location');
      optionLocation === selectedOption.attr('data-location')
        ? $(option).css('display', 'block')
        : $(option).css('display', 'none');
      if ($('option:selected').hasClass('selected-all')) {
        $('.titles option').css('display', 'block');
      }
    });
  });

  function center_map(map) {
    // vars
    var bounds = new google.maps.LatLngBounds();

    // loop through all markers and create bounds
    $.each(map.markers, function (i, marker) {
      if (!marker.map) {
        return;
      }
      var latlng = new google.maps.LatLng(
        marker.position.lat(),
        marker.position.lng()
      );
      bounds.extend(latlng);
    });

    // only 1 marker?
    if (map.markers.length == 1) {
      // set center of map
      map.setCenter(bounds.getCenter());
    } else {
      // fit to bounds
      map.fitBounds(bounds);
    }
  }

  $('.acf-map').each(function () {
    render_map($(this));
  });
  //////////////////////////////////////////////
  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  // Facility guide form
  $('.js-file-value').on('change', function () {
    var file = [];
    file.length = 0;
    $.each($("input[class='js-file-value']:checked"), function () {
      file.push($(this).val());
    });
    $('#input_4_4').val(file.join(','));
  });

  $(document).on('gform_confirmation_loaded', function (e, form_id) {
    if (form_id == 4) {
      $('.footer-form .form-text-wrap').css('display', 'none');
    }
  });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here
  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here
  slickFacet();
  resizeVideo();
  // fixPosition();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
});
